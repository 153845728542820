import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { capitalize, kebabCase } from 'lodash'
import Layout from '../components/Layout'

const MetaArchive = ({ data, pageContext }) => {
  const { title, singleSlug } = pageContext
  const { items } = data.allMarkdownRemark

  return (
    <Layout>
      <div className="layout-wrapper">
        <h1>{title}</h1>
        <ol>
          {items
            .sort((a, b) => b.totalCount - a.totalCount)
            .map(item => (
              <li key={`meta-item-${item.fieldValue}`}>
                <Link to={'/' + singleSlug + '/' + kebabCase(item.fieldValue)}>
                  {capitalize(item.fieldValue)}{' '}
                  <span className="main-footer__nav__set__item__count">
                    ({item.totalCount})
                  </span>
                </Link>
              </li>
            ))}
        </ol>
      </div>
    </Layout>
  )
}

MetaArchive.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default MetaArchive

export const pageQuery = graphql`
  query MetaArchive($frontmatterKey: markdownRemarkGroupEnum) {
    allMarkdownRemark(limit: 2000) {
      items: group(field: $frontmatterKey) {
        fieldValue
        totalCount
      }
    }
  }
`
